import { graphql } from "gatsby";

import React from "react";

import PageLayout from "layouts/Page";

import SEO from "components/SEO";

export default ({ data }) => {
  const post = data.markdownRemark;

  return (
    <PageLayout>
      <SEO title={post.frontmatter.title} />
      <div className="w-full max-w-2xl px-4 mx-auto">
        <article>
          <header>
            <h1>{post.frontmatter.title}</h1>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
      </div>
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`;
