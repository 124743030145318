import React from "react";

import Navigation from "components/Navigation";
import Footer from "components/Footer";

export default function PageLayout({ children, ...props }) {
  return (
    <>
      <Navigation {...props} />
      <div className="py-16">{children}</div>
      <Footer />
    </>
  );
}
